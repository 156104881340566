import { useEffect } from 'react';
import { LoginState, useAuth } from './useAuth';
import { useDispatch } from 'react-redux';
import {
  changeCalendar,
  endLoading,
  loadElements,
  store,
} from '../constants/initialStore';
import {
  redirect,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { calendarFormats } from '../constants/calendarFormats';
import dayjs from 'dayjs';
import { Calendar } from '../types/models/Calendar';
import { createCalendar, getOngoingCalendar } from '../api/calendars';
import { v4 as uuid } from 'uuid';
import { ConvertMMToPixel } from '../utils/convertCMToPixel';
import colors from '../constants/colors';
import {
  ElementType,
  Element,
  TextElementStyle,
  PhotoElementStyle,
} from '../types/models/Elements';
import i18next from '../i18n';
import {
  getFreeSpaceHeightCalendar,
  getFreeSpaceWidthCalendar,
} from '../utils/getFreeSpace';

type useFetchOrCreateCalendarProps = {
  setIsCalendarStart: React.Dispatch<React.SetStateAction<boolean>>;
};

export const fetchDispatchCalendar = async (id: string) => {
  const calendar = await getOngoingCalendar(id);
  const { calendarElements, ...restCalendar } = calendar;
  if (!calendar) return redirect('/404');
  store.dispatch(changeCalendar(restCalendar));
  store.dispatch(
    loadElements(
      calendarElements!.map(({ style, ...element }) => ({
        style: JSON.parse(style as unknown as string),
        ...element,
      })),
    ),
  );
  store.dispatch(endLoading());

  return calendar;
};

export const useFetchOrCreateCalendar = ({
  setIsCalendarStart,
}: useFetchOrCreateCalendarProps) => {
  const { userInfo, isReady, useNewAccessToken } = useAuth();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const languageAsked = searchParams.get('lng') || 'fr';
  const fromCart = searchParams.get('fromCart') || false;
  const navigate = useNavigate();

  const createCalendarWithFormat = async () => {
    const formatAsked = searchParams.get('format') ?? '';
    const format = calendarFormats[formatAsked];
    // const monthStart = dayjs.utc().startOf('year').add(1, 'years');
    let monthStart = dayjs().utc().startOf('month');
    let currentMonthNumber = monthStart.month();

    const monthForNextYear = import.meta.env.VITE_CALENDAR_MONTH_NEXT_YEAR ?? 11;
    
    if (currentMonthNumber + 1 >= monthForNextYear) { 
        monthStart = dayjs.utc().startOf('year').add(1, 'years');
        currentMonthNumber = 0;
    }

    const { width: mmWidth, height: mmHeight } = format;
    const width = ConvertMMToPixel(mmWidth);
    const height = ConvertMMToPixel(mmHeight);

    const freeHeightSpace = getFreeSpaceHeightCalendar(
      format.types![0],
      format,
      false,
    );
    const freeWidthSpace = getFreeSpaceWidthCalendar(
      format.types![0],
      format,
      false,
    );

    let nbPage = 13;
    if (formatAsked == 'CALWR2-RECH') {
      nbPage = 13;
    }

    let calendar: Calendar = {
      format: formatAsked,
      monthStart: monthStart.toISOString(),
      name: 'Calendrier',
      type: format.types![0],
      calendarMonths: Array.from({ length: nbPage }, (_, index) => ({
        id: uuid(),
        backgroundColor: '#ffffff',
        month: monthStart.add(index - 1, 'months').toISOString(),
        calendarDays: [],
        calendarElements:
          index === 0
            ? [
                {
                  id: uuid(),
                  type: ElementType.TEXT,
                  top: height - 500,
                  left: 0,
                  width: width,
                  height: 200,
                  content: i18next.t('calendar.calendar', { ns: ['common'] }),
                  zIndex: 1,
                  editable: true,
                  contentEditable: true,
                  style: JSON.stringify({
                    bold: false,
                    italic: false,
                    underlined: false,
                    alignement: 'center',
                    font: 'DM Sans',
                    size: 25,
                    letterSpacing: 0,
                    interline: 1,
                    color: colors.black,
                    transform: 'none',
                  }) as unknown as TextElementStyle | PhotoElementStyle,
                },
                {
                  id: uuid(),
                  type: ElementType.TEXT,
                  top: height - 300,
                  left: 0,
                  width: width,
                  height: 100,
                  content: `${monthStart.toISOString().split('-')[0]}`,
                  zIndex: 1,
                  editable: true,
                  contentEditable: true,
                  style: JSON.stringify({
                    bold: false,
                    italic: false,
                    underlined: false,
                    alignement: 'center',
                    font: 'DM Sans',
                    size: 15,
                    letterSpacing: 0,
                    interline: 1,
                    color: colors.black,
                    transform: 'none',
                    rotation: 0,
                  }) as unknown as TextElementStyle | PhotoElementStyle,
                },
                {
                  type: ElementType.PHOTO,
                  id: uuid(),
                  content: '',
                  width: width,
                  height: height - 600,
                  top: 0,
                  left: 0,
                  zIndex: 0,
                  editable: true,
                  contentEditable: true,
                  style: JSON.stringify({
                    brightness: 1,
                    saturation: 100,
                    opacity: 1,
                    zoom: 100,
                    rotation: 0,
                    grayscale: false,
                    sepia: false,
                    mirrored: false,
                    shadow: false,
                    shape: 'rectangle',
                    translateX: 0,
                    translateY: 0,
                    rotationFrame: 0,
                  }) as unknown as TextElementStyle | PhotoElementStyle,
                },
              ]
            : [
                {
                  type: ElementType.PHOTO,
                  id: uuid(),
                  content: '',
                  width: freeWidthSpace * 0.8,
                  height: freeHeightSpace * 0.7,
                  top: freeHeightSpace * 0.1,
                  left: freeWidthSpace * 0.1,
                  zIndex: 0,
                  editable: true,
                  contentEditable: true,
                  style: JSON.stringify({
                    brightness: 1,
                    saturation: 100,
                    opacity: 1,
                    zoom: 100,
                    rotation: 0,
                    grayscale: false,
                    sepia: false,
                    mirrored: false,
                    shadow: false,
                    shape: 'rectangle',
                    translateX: 0,
                    translateY: 0,
                    rotationFrame: 0,
                  }) as unknown as TextElementStyle | PhotoElementStyle,
                },
                {
                  type: ElementType.TEXT,
                  id: uuid(),
                  content: '',
                  width: freeWidthSpace * 0.52,
                  height: freeHeightSpace * 0.1,
                  top: freeHeightSpace * 0.82,
                  left: freeWidthSpace * 0.24,
                  zIndex: 0,
                  editable: true,
                  contentEditable: true,
                  style: JSON.stringify({
                    bold: false,
                    italic: false,
                    underlined: false,
                    size: 12,
                    letterSpacing: 0,
                    interline: 1,
                    alignement: 'center',
                    font: 'DM Sans',
                    color: colors.black,
                    transform: 'none',
                    rotation: 0,
                  }) as unknown as TextElementStyle | PhotoElementStyle,
                },
              ],
      })),
    };

    calendar = await createCalendar(calendar, languageAsked);
    navigate(
      `/calendrier/${calendar.id}?lng=${languageAsked}${
        fromCart ? '&fromCart=true' : ''
      }`,
      { replace: true },
    );

    dispatch(changeCalendar(calendar));
    setIsCalendarStart(true);
  };

  useEffect(() => {
    if (isReady) {
      const newToken = searchParams.get('token');
      if (newToken) {
        const res = useNewAccessToken(newToken);
        if (res) {
          const urlActuelle = window.location.href;
          const urlSansToken = urlActuelle.replace(/token=[^&]*(&?)/, '');
          window.location.replace(urlSansToken);
        }
      }
    }
    if (
      window.location.pathname.includes('carte') ||
      window.location.pathname.includes('album') ||
      window.location.pathname.includes('autre') ||
      window.location.pathname.includes('admin') // Route type is inferred later
    ) {
      return;
    }

    // it is here to prevent multiple creation, but the useEffect should be transformed in the future so it is not triggered so often
    if (userInfo === null) {
      return;
    }

    if (id && id === 'nouveau') {
      createCalendarWithFormat();
      return;
    }
    if (id) {
      fetchDispatchCalendar(id);
      return;
    }
    navigate('/404');
    return;
  }, [userInfo, isReady, searchParams]);
};
