import axios from 'axios';
import { BrowserRouter, useLocation } from 'react-router-dom';
import AppRoutes from './routing/AppRoutes';
import { AuthProvider } from './hook/useAuth';
import { Provider } from 'react-redux';
import { store } from './constants/initialStore';
import './i18n';
import { useEffect, useState } from 'react';
import { initGA, logPageView } from './analytics';
// import colors from './constants/colors';
// import { useTranslation } from 'react-i18next';

const measurementID = 'G-3BYMN9F3TP'; // Replace with your measurement ID

const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);
};

function App() {
  // axios.defaults.baseURL = import.meta.env.VITE_API_URL;
  // const [error, setError] = useState('');
  // const { i18n, t } = useTranslation(['common']);

  // useEffect(() => {
  //   const validLanguages = ['fr', 'de', 'it'];
  //   if (!validLanguages.includes(i18n.language)) {
  //     i18n.changeLanguage('fr');
  //   }


  //   const source = axios.CancelToken.source();
  //   axios
  //   .get(`/clicAdmin/testconnexion`, { cancelToken: source.token })
  //   .then((response) => {
  //     setError('');
  //   })
  //   .catch((error) => {
  //     if (axios.isCancel(error)) return;
  //     if (error?.code === "ECONNABORTED") return;
  //     if (error.response?.status === 404) {
  //       setError(t('error.not_found'));
  //     } else if (error.message.includes('Network Error')) {
  //       setError(t('error.network_error'));
  //     } else {
  //       setError(t('error.unknown_error'));
  //     }
  //   });

  // return () => source.cancel('Component unmounted');
    // Tester la connexion
    // axios
    //   .get(`/clicAdmin/testconnexion`)
    //   .then((response) => {
    //     setError(''); // Clear previous error
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //     if (error?.code === "ECONNABORTED") return;
    //     if (error.response?.status === 404) {
    //       setError(t('error.not_found')); // Utiliser la traduction
    //     } else if (error.message.includes('Network Error')) {
    //       setError(t('error.network_error'));
    //     } else {
    //       setError(t('error.unknown_error'));
    //     }
    //   });
  // }, [i18n.language]);

  // const goBack = () => {
  //   window.history.back();
  // };

  return (
    <AuthProvider>
      <Provider store={store}>
        <BrowserRouter>
          <PageViews />
        
          <AppRoutes />
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  );
}

const PageViews: React.FC = () => {
  usePageViews();
  return null;
};

export default App;
