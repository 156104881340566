import { ReactSVG } from 'react-svg';
import { styled } from 'styled-components';
import colors from '../../../constants/colors';
import {
  RootState,
  closeMenu,
  openMenu,
  resetFocus,
} from '../../../constants/initialStore';
import { useDispatch, useSelector } from 'react-redux';
import useCreationType from '../../../hook/useCreationType';
import { useTranslation } from 'react-i18next';
import styleConstants from '../../../constants/styleConstants';
import useLittleScreen from '../../../hook/useLittleScreen';
import { useEffect, useState } from 'react';
import { LoginState, useAuth } from '../../../hook/useAuth';
import layoutsWithNoTemplate from '../../../constants/layoutsWithNoTemplate';

interface SideBarProps {
  children?: React.ReactNode;
}

const SideBar: React.FC<SideBarProps> = ({ children }) => {
  const menu = useSelector((state: RootState) => state.menu.value);
  const dispatch = useDispatch();
  const creationType = useCreationType();
  const creation = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );
  const otherFormat = useSelector(
    (state: RootState) => state.creation.present.other?.format,
  );

  const albumFormat = useSelector(
    (state: RootState) => state.creation.present.album?.format,
  );

  const { t } = useTranslation(['common']);

  const littleScreen = useLittleScreen();

  const createHandleOpenMenuId = (id: number) => () => {
    if (menu === id) {
      dispatch(closeMenu());
      dispatch(resetFocus());
    } else {
      dispatch(openMenu(id));
    }
  };

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const user = useAuth();

  useEffect(() => {
    if (
      user.userInfo?.state === LoginState.LOGGED_IN &&
      user.userInfo.isAdmin
    ) {
      setIsAdmin(true);
    }
  }, [user]);

  const isFinitionVisible = () => {
    return (
      (creationType === 'carte' && !creation.format.includes('MAG')) ||
      (creationType === 'autre' &&
        otherFormat &&
        (otherFormat.includes('POS') || otherFormat.includes('MUG4'))) ||
      (creationType === 'album' &&
        albumFormat &&
        albumFormat.startsWith('CARN'))
    );
  };

  return (
    <MainContainer id="sidebar">
      <Container>
        <Tab $active={menu === 1} onClick={createHandleOpenMenuId(1)}>
          <ReactSVG
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 24px; height: 24px;');
            }}
            src={
              menu === 1
                ? '/svg/categories/photos.svg'
                : '/svg/categories/photos_white.svg'
            }
          />
          {!littleScreen && t('general.photos')}
        </Tab>
        <Tab $active={menu === 2} onClick={createHandleOpenMenuId(2)}>
          <ReactSVG
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 24px; height: 24px;');
            }}
            src={
              menu === 2
                ? '/svg/categories/Texte.svg'
                : '/svg/categories/Texte_white.svg'
            }
          />
          {!littleScreen && t('general.text')}
        </Tab>
        <Tab $active={menu === 3} onClick={createHandleOpenMenuId(3)}>
          <ReactSVG
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 24px; height: 24px;');
            }}
            src={
              menu === 3
                ? '/svg/categories/Fonds.svg'
                : '/svg/categories/Fonds_white.svg'
            }
          />
          {!littleScreen && t('general.background')}
        </Tab>
        {(!otherFormat || !layoutsWithNoTemplate.includes(otherFormat)) &&
          (creationType !== 'carte' ||
            (creation && creation.format.includes('MAG'))) && (
            <Tab $active={menu === 4} onClick={createHandleOpenMenuId(4)}>
              <ReactSVG
                beforeInjection={(svg) => {
                  svg.setAttribute('style', 'width: 24px; height: 24px;');
                }}
                src={
                  menu === 4
                    ? '/svg/categories/Template.svg'
                    : '/svg/categories/Template_white.svg'
                }
              />
              {!littleScreen && t('general.layout')}
            </Tab>
          )}
        <Tab $active={menu === 5} onClick={createHandleOpenMenuId(5)}>
          <ReactSVG
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 24px; height: 24px;');
            }}
            src={
              menu === 5
                ? '/svg/categories/Motifs.svg'
                : '/svg/categories/Motifs_white.svg'
            }
          />
          {!littleScreen && t('general.icons')}
        </Tab>
        {isFinitionVisible() && (
          <Tab $active={menu === 6} onClick={createHandleOpenMenuId(6)}>
            <ReactSVG
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 24px; height: 24px;');
              }}
              src={
                menu === 6
                  ? '/svg/categories/Finitions.svg'
                  : '/svg/categories/Finitions_white.svg'
              }
            />
            {!littleScreen && t('general.finishes')}
          </Tab>
        )}
        <Tab $active={menu === 7} onClick={createHandleOpenMenuId(7)}>
          <ReactSVG
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 24px; height: 24px;');
            }}
            src={
              menu === 7
                ? '/svg/categories/Aide.svg'
                : '/svg/categories/Aide_white.svg'
            }
          />
          {!littleScreen && t('general.help')}
        </Tab>
        {isAdmin && (
          <Tab $active={menu === 8} onClick={createHandleOpenMenuId(8)}>
            <ReactSVG
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 24px; height: 24px;');
              }}
              src={
                menu === 8
                  ? '/svg/setting_configuration.svg'
                  : '/svg/setting_configuration_white.svg'
              }
            />
            {!littleScreen && t('general.settings')}
          </Tab>
        )}
        {isAdmin && (
          <Tab $active={menu === 9} onClick={createHandleOpenMenuId(9)}>
            <ReactSVG
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 24px; height: 24px;');
              }}
              src={
                menu === 9
                  ? '/svg/categories/photos.svg'
                  : '/svg/categories/photos_white.svg'
              }
            />
            {!littleScreen && t('general.customer_library')}
          </Tab>
        )}
      </Container>
      {children}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  min-width: 98px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 24px 0;
  box-sizing: border-box;

  background-color: ${colors.black};

  @media (max-width: ${styleConstants.breakpointLg}) {
    height: 48px;
    flex-direction: row;
    padding: 0;
    width: 100vw;
    justify-content: center;
    gap: 12px;

    position: fixed;
    bottom: 0px;
    z-index: 100;
  }
`;

const Tab = styled.div<{ $active: boolean }>`
  width: 100%;
  height: 82px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${({ $active }) => ($active ? colors.white : colors.black)};
  color: ${({ $active }) => ($active ? colors.black : colors.white)};
  gap: 8px;

  font-family: 'DM Sans';
  font-size: 12px;
  font-weight: 400;
  text-align: center;

  cursor: pointer;

  &:hover {
    background-color: ${({ $active }) =>
      $active ? colors.gray100 : colors.gray500};
  }

  @media (max-width: ${styleConstants.breakpointLg}) {
    height: 48px;
    width: max-content;
    padding: 12px;
  }
`;

export default SideBar;
