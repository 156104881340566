import { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled } from 'styled-components';
import colors from '../../constants/colors';
import {
  RootState,
  addElement,
  modifyElement,
  resetFocus,
} from '../../constants/initialStore';
import useCreationType from '../../hook/useCreationType';
import useLittleScreen from '../../hook/useLittleScreen';
import { Element } from '../../types/models/Elements';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';

const DEFAULT_SIZES = ConvertMMToPixel(100);
const DEFAULT_SIZES_LABEL = ConvertMMToPixel(20);

interface PhotoProps {
  thumbnailSrc: string;
  photoSrc: string;
  id: string;
  deleteClick: (id: string) => void;
}

const Photo: React.FC<PhotoProps> = ({ photoSrc, id, deleteClick, thumbnailSrc }) => {
  const [originalSize, setOriginalSize] = useState({ width: 0, height: 0 });
  const [isLabel, setIsLabel] = useState<boolean>(false);
  const [maxWidth, setMaxWidth] = useState<number>(0);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const elementMaxZIndex = useSelector(
    (state: RootState) => state.creation.present.elements.value.length,
  );

  const [, drag] = useDrag({
    type: 'PhotoItem',
    item: {
      src: photoSrc,
      id,
      type: 'PHOTO',
      index: elementMaxZIndex,
      dimensions: originalSize,
    },
  });

  const dispatch = useDispatch();
  const ratio = useSelector((state: RootState) => state.ratio.value);
  const currentFace = useSelector((state: RootState) => state.face.value);
  const type = useCreationType();
  const focus = useSelector((state: RootState) => state.focus.value);
  const elements = useSelector(
    (state: RootState) => state.creation.present.elements.value,
  );
  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );
  const album = useSelector((state: RootState) => state.creation.present.album);

  const littleScreen = useLittleScreen();

  const handleClick = () => {
    const thisElement = elements.find(
      (element: Element) => element.id === focus,
    );
    if (focus && thisElement) {
      const thisElement = elements.find(
        (element: Element) => element.id === focus,
      );
      if (thisElement && thisElement?.type === 'PHOTO') {
        dispatch(
          modifyElement({
            id: focus,
            modification: {
              content: JSON.stringify({"original" : photoSrc, "thumbnail" : thumbnailSrc}),
              style: {
                ...thisElement.style,
                brightness: 1,
                saturation: 100,
                opacity: 1,
                grayscale: false,
                sepia: false,
                mirrored: false,
                shadow: false,
                translateX: 0,
                translateY: 0,
                zoom:
                  originalSize.height / originalSize.width >
                  thisElement.height / thisElement.width
                    ? Math.min(
                        (((originalSize.height / originalSize.width) *
                          thisElement.width) /
                          thisElement.height) *
                          100,
                        500,
                      )
                    : Math.min(
                        (((originalSize.width / originalSize.height) *
                          thisElement.height) /
                          thisElement.width) *
                          100,
                        500,
                      ),
              },
            },
          }),
        );
        dispatch(resetFocus());
      }
    } else {
      const emptyPhotoOnThisFace = elements
        .filter((elmt) => {
          return type === 'carte'
            ? elmt.face === currentFace &&
                elmt.type === 'PHOTO' &&
                (elmt.content === '' || elmt.content === null)
            : type === 'album'
            ? elmt.type === 'PHOTO' &&
              (elmt.content === '' || elmt.content === null)
            : type === 'calendrier'
            ? elmt.calendarMonthID === focus &&
              elmt.type === 'PHOTO' &&
              (elmt.content === '' || elmt.content === null)
            : elmt.type === 'PHOTO' &&
              (elmt.content === '' || elmt.content === null);
        })
        .sort((photo1, photo2) => {
          if (type === 'album' && album) {
            const page1Pos =
              album.albumPages.find((page) => page.id === photo1.pageID)
                ?.position ?? -1;
            const page2Pos =
              album.albumPages.find((page) => page.id === photo2.pageID)
                ?.position ?? -1;

            return page1Pos - page2Pos !== 0
              ? page1Pos - page2Pos
              : photo1.top - photo2.top === 0
              ? photo1.left - photo2.left
              : photo1.top - photo2.top;
          }
          return photo1.top - photo2.top === 0
            ? photo1.left - photo2.left
            : photo1.top - photo2.top;
        });
      if (emptyPhotoOnThisFace.length > 0) {
        dispatch(
          modifyElement({
            id: emptyPhotoOnThisFace[0].id,
            modification: {
              content: JSON.stringify({ "original" : photoSrc, "thumbnail" : thumbnailSrc}),
              style: {
                ...emptyPhotoOnThisFace[0].style,
                brightness: 1,
                saturation: 100,
                opacity: 1,
                rotation: 0,
                grayscale: false,
                sepia: false,
                mirrored: false,
                shadow: false,
                shape: 'rectangle',
                translateX: 0,
                translateY: 0,
                zoom:
                  originalSize.height / originalSize.width >
                  emptyPhotoOnThisFace[0].height / emptyPhotoOnThisFace[0].width
                    ? Math.min(
                        (((originalSize.height / originalSize.width) *
                          emptyPhotoOnThisFace[0].width) /
                          emptyPhotoOnThisFace[0].height) *
                          100,
                        500,
                      )
                    : Math.min(
                        (((originalSize.width / originalSize.height) *
                          emptyPhotoOnThisFace[0].height) /
                          emptyPhotoOnThisFace[0].width) *
                          100,
                        500,
                      ),
              },
            },
          }),
        );
      } else {
        dispatch(
          addElement({
            element: {
              type: 'PHOTO',
              top: maxHeight / 2 - (isLabel ? 118 : 150), // centered
              left: maxWidth / 2 - (isLabel ? 86 : 350), // centered
              face: type === 'carte' ? currentFace : undefined,
              calendarMonthID: type === 'calendrier' ? focus : undefined,
              pageID: type === 'album' ? focus : undefined,
              width: isLabel ? DEFAULT_SIZES_LABEL : DEFAULT_SIZES,
              height: isLabel ? DEFAULT_SIZES_LABEL : DEFAULT_SIZES,
              content: JSON.stringify({"original" : photoSrc, "thumbnail" : thumbnailSrc}),
              zIndex: elementMaxZIndex,
              style: {
                brightness: 1,
                saturation: 100,
                opacity: 1,
                zoom: 100,
                rotation: 0,
                grayscale: false,
                sepia: false,
                mirrored: false,
                shadow: false,
                shape: 'rectangle',
                translateX: 0,
                translateY: 0,
                rotationFrame: 0,
              },
              editable: true,
              contentEditable: true,
            },
          }),
        );
      }
    }
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    deleteClick(id);
  };

  const onLoadHandler: React.ReactEventHandler<HTMLImageElement> = (event) => {
    setOriginalSize({
      height: event.currentTarget.naturalHeight,
      width: event.currentTarget.naturalWidth,
    });
  };

  useEffect(() => {
    if (card) {
      setMaxWidth(
        parseInt(
          ConvertMMToPixel(card.facesSizes[currentFace].width).toFixed(0),
        ),
      );
      setMaxHeight(
        parseInt(
          ConvertMMToPixel(card.facesSizes[currentFace].height).toFixed(0),
        ),
      );
      if (
        card.facesSizes[currentFace].width ===
          card.facesSizes[currentFace].height &&
        (card.facesSizes[currentFace].width === 43 ||
          card.facesSizes[currentFace].width === 40)
      ) {
        setIsLabel(true);
      }
    }
  }, [card, currentFace]);

  return (
    <Container onClick={handleClick} ref={drag}>
      <Image src={thumbnailSrc} onLoad={onLoadHandler} loading="lazy" />
      <RoundDiv onClick={handleDelete}>
        <ReactSVG
          src={'/svg/trash.svg'}
          beforeInjection={(svg) => {
            svg.setAttribute('style', 'width: 10px; height: 10px;');
          }}
        />
      </RoundDiv>
    </Container>
  );
};

const Container = styled.div`
  height: 82px;
  width: 82px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
`;

const Image = styled.img`
  width: 82px;
  height: 82px;

  object-fit: cover;
`;

const RoundDiv = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  border-radius: 50%;
  position: absolute;
  top: 4px;
  right: 4px;
`;
export default Photo;
