import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/de';

import i18next from './i18n';
import axios from 'axios';

import utc from 'dayjs/plugin/utc';
import TagManager from 'react-gtm-module';
import Hotjar from '@hotjar/browser';
import colors from './constants/colors';
import { useTranslation } from 'react-i18next';

dayjs.locale(i18next.language);
dayjs.extend(utc);

const hotjarVersion = 6;

const isPlanetPhotos = window.location.href.includes('planet-photo');

const tagManagerArgs = {
  gtmId: isPlanetPhotos ? 'GTM-P2F3RH' : 'GTM-K3QKC5PP',
};

TagManager.initialize(tagManagerArgs);

Hotjar.init(4933549, hotjarVersion);

const Main = () => {
  axios.defaults.baseURL = import.meta.env.VITE_API_URL;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { i18n, t } = useTranslation(['common']);
  
  useEffect(() => {
    const validLanguages = ['fr', 'de', 'it'];
    if (!validLanguages.includes(i18n.language)) {
      i18n.changeLanguage('fr');
    }
  }, [i18n.language]);

  useEffect(() => {
    // Tester la connexion avant de charger l'application
    const source = axios.CancelToken.source();

    axios
      .get(`/clicAdmin/testconnexion`, { cancelToken: source.token })
      .then(() => {
        setError('');
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        if (error?.response?.status === 404) {
          setError('Resource not found.'); // Traduire ici si nécessaire
        } else if (error.message.includes('Network Error')) {
          setError('Network error. Please check your connection.');
        } else {
         // setError('An unknown error occurred.');
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => source.cancel('Component unmounted');
  }, []);

  const goBack = () => {
    window.history.back();
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <p>Loading...</p>
      </div>
    );
  }

  if (error !== '') {
    return (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            padding: '20px',
            textAlign: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: '#222',
              borderRadius: '8px',
              padding: '20px',
              maxWidth: '400px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            }}
          >
            <h2 style={{ margin: '0 0 15px', fontSize: '1.5rem' }}>
              {t('error.title')}
            </h2>
            <p style={{ margin: '0 0 10px' }}>{error}</p>
            <a
              href="mailto:help@planet-cards.com"
              style={{
                color: '#1e90ff',
                textDecoration: 'underline',
                fontWeight: 'bold',
              }}
            >
              help@planet-cards.com
            </a>
            <button
              onClick={goBack}
              style={{
                marginTop: '20px',
                padding: '10px 15px',
                fontSize: '1rem',
                backgroundColor: colors.blue,
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              {t('error.go_back')}
            </button>
          </div>
        </div>
    );
  }

  return <App />;
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);
