import { styled } from 'styled-components';
import colors from '../../../constants/colors';
import { useSelector } from 'react-redux';
import {
  RootState,
  resetFocus,
  setFocus,
} from '../../../constants/initialStore';
import TextMenu from '../../functionnalities/TextMenu';
import BackGroundMenu from '../../functionnalities/BackGroundMenu';
import MotifMenu from '../../functionnalities/MotifMenu';
import HelpMenu from '../../functionnalities/HelpMenu';
import PhotoMenu from '../../functionnalities/PhotoMenu';
import FinitionsMenu from '../../functionnalities/FinitionsMenu';
import ErrorBoundary from '../ErrorBoundary';
import { useEffect, useLayoutEffect, useRef } from 'react';
import useCreationType from '../../../hook/useCreationType';
import LayoutMenu from '../../functionnalities/LayoutMenu';
import styleConstants from '../../../constants/styleConstants';
import SettingsMenu from '../../functionnalities/SettingsMenu';
import useLittleScreen from '../../../hook/useLittleScreen';
import TextMenuMobile from '../../functionnalities/TextMenuMobile';
import { useDispatch } from 'react-redux';
import PhotoMenuMobile from '../../functionnalities/PhotoMenumobile';
import BackGroundMenuMobile from '../../functionnalities/BackgroundMenuMobile';
import FinitionsMenuMobile from '../../functionnalities/FinitionsMenuMobile';
import MotifMenuMobile from '../../functionnalities/MotifMenuMobile';
import LayoutMenuMobile from '../../functionnalities/LayoutMenuMobile';
import { ElementType } from '../../../types/models/Elements';

const TabMenuOpen: React.FC = () => {
  const menu = useSelector((state: RootState) => state.menu.value);
  const dispatch = useDispatch();
  const elRef = useRef<HTMLDivElement | null>(null);
  const type = useCreationType();
  const focus = useSelector((state: RootState) => state.focus.value);
  const elements = useSelector(
    (state: RootState) => state.creation.present.elements.value,
  );
  const creation = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );
  const otherFormat = useSelector(
    (state: RootState) => state.creation.present.other?.format,
  );

  const albumFormat = useSelector(
    (state: RootState) => state.creation.present.album?.format,
  );

  const littleScreen = useLittleScreen();

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (!elRef || !elRef.current) return;
      const hasScrollbar =
        elRef?.current?.scrollHeight > elRef?.current?.clientHeight;
      elRef.current.style.paddingRight = hasScrollbar
        ? '7px'
        : littleScreen
          ? '8px'
          : '15px';
    });
    resizeObserver.observe(elRef.current!);
  }, []);

  useEffect(() => {
    const elementFocused = elements.find((elm) => elm.id === focus);
    if (menu === 0) {
      dispatch(resetFocus());
    }

    if (elementFocused) {
      if (type === 'album') {
        if (elementFocused.type !== ElementType.PHOTO && menu === 1) {
          dispatch(setFocus(elementFocused.pageID));
        } else if (elementFocused.type !== ElementType.TEXT && menu === 2) {
          dispatch(setFocus(elementFocused.pageID));
        } else if (elementFocused.type !== ElementType.MOTIF && menu === 5) {
          dispatch(setFocus(elementFocused.pageID));
        }
      } else {
        if (elementFocused.type !== ElementType.PHOTO && menu === 1) {
          dispatch(resetFocus());
        } else if (elementFocused.type !== ElementType.TEXT && menu === 2) {
          dispatch(resetFocus());
        } else if (elementFocused.type !== ElementType.MOTIF && menu === 5) {
          dispatch(resetFocus());
        }
      }
    }
  }, [menu]);

  useEffect(() => {
    // console.log(focus);
  }, [focus]);

  const isFinitionVisible = () => {
    return (
      (type === 'carte' && !creation.format.includes('MAG')) ||
      (type === 'autre' &&
        otherFormat &&
        (otherFormat.includes('POS') || otherFormat.includes('MUG4'))) ||
      (type === 'album' && albumFormat && albumFormat.startsWith('CARN'))
    );
  };

  return (
    <Container id="menu" ref={elRef}>
      <ErrorBoundary>
        {menu === 1 && (littleScreen ? <PhotoMenuMobile /> : <PhotoMenu />)}
        {menu === 2 && (littleScreen ? <TextMenuMobile /> : <TextMenu />)}
        {menu === 3 &&
          (littleScreen ? <BackGroundMenuMobile /> : <BackGroundMenu />)}
        {(type !== 'carte' || (creation && creation.format.includes('MAG'))) &&
          menu === 4 &&
          (littleScreen ? <LayoutMenuMobile /> : <LayoutMenu />)}
        {menu === 5 && (littleScreen ? <MotifMenuMobile /> : <MotifMenu />)}
        {isFinitionVisible() &&
          menu === 6 &&
          (littleScreen ? <FinitionsMenuMobile /> : <FinitionsMenu />)}
        {menu === 7 && <HelpMenu />}
        {menu === 8 && <SettingsMenu />}
        {menu === 9 &&  (littleScreen ? <PhotoMenuMobile loadOwnerLibrary /> : <PhotoMenu loadOwnerLibrary />)}
      </ErrorBoundary>
    </Container>
  );
};

const TabMenu = () => {
  const isMenuOpen = useSelector((state: RootState) => state.menu.value !== 0);
  return isMenuOpen ? <TabMenuOpen /> : null;
};

const Container = styled.div`
  max-width: 310px;
  min-width: 310px;
  max-height: calc(100vh - 130px);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${colors.white};

  box-sizing: border-box;
  border: solid 1px ${colors.gray200};
  padding: 0 15px 14px 15px;

  overflow: auto;

  z-index: 200;

  @media (max-width: ${styleConstants.breakpointLg}) {
    position: fixed;
    bottom: 48px;
    max-width: unset;
    max-height: unset;
    width: 100vw;
    max-height: calc(20vh + 100px);
    padding: 0 8px;
  }
`;

export default TabMenu;
